import React from "react"

import SEO from "../components/seo"
import Link from "../components/link"
import Image from "../components/image"

import PageMain from "../components/page-main"
import MainHeadline from "../components/main-headline"
import ContentSection from "../components/content-section"
import ContentNote from "../components/content-note"
import ContentIntro from "../components/content-intro"
import ContentList from "../components/content-list"
import ColorBlock from "../components/color-block"

import { fontsizes, fontweights, colors, mq } from "../styles/variables"

const EmailStyles = mq({
  fontSize: fontsizes.large,
  fontWeight: fontweights.bold,
  color: colors.primary,
  gridColumn: ["2 / 4", "3 / 5", "4 / 7"],
  margin: "20px 0",
})

const IndexPage = () => {
  return (
    <>
      <SEO title="Delivering customer joy" />
      <PageMain>
        <ContentSection>
          <ColorBlock />
          <MainHeadline>Show your emotions</MainHeadline>
          <ContentIntro>
            Emotions are the key element for creating true fans - Let's go for
            smiles, laughs and happiness
          </ContentIntro>
        </ContentSection>
        <ContentSection>
          <ContentNote>Your problem</ContentNote>
          <h2 className="subheader">Does this sound familiar?</h2>
          <ContentList>
            <li>You have bad conversion rates.</li>
            <li>Your Net Promoter Score is low.</li>
            <li>Your customer retention rates are dropping.</li>
          </ContentList>
          <p className="bodycopy">
            All of these is a result of your customers{" "}
            <strong>not experiencing enough joy</strong>.
          </p>
        </ContentSection>
        <ContentSection>
          <ContentNote>Your dream</ContentNote>
          <h2 className="subheader">Wouldn't it be nice if...</h2>
          <ContentList>
            <li>Your customers converted with a smile?</li>
            <li>Your customers told their friends about you?</li>
            <li>Your customers came back for more?</li>
          </ContentList>
          <p className="bodycopy">
            And the truth is, that by delivering a joyful experience,{" "}
            <strong>it benefits both you and your customer</strong>.
          </p>
        </ContentSection>
        <ContentSection>
          <ContentNote>My promise</ContentNote>
          <h2 className="subheader">I want to help</h2>
          <p className="bodycopy">
            I help businesses with the extraordinary attention to detail, that
            complete a joyful experience.
          </p>
          <p className="bodycopy">
            I advise companies on how to creatively leverage the full technical
            potential to maximize customer joy.
          </p>
          <p className="bodycopy">
            It is my <strong>genuine wish to help</strong> you too. But before
            you reach out to me, you need to know three things about how I do
            things:
          </p>
          <ContentList>
            <li>I listen, first.</li>
            <li>I ask questions, second.</li>
            <li>I give you honesty, third.</li>
          </ContentList>
        </ContentSection>
        <ContentSection>
          <ColorBlock alternate />
          <ContentNote>Now write to</ContentNote>

          <div css={EmailStyles}>
            <Link css={EmailStyles} href="mailto:hello@torkil.tech">
              hello@torkil.tech
            </Link>
          </div>
        </ContentSection>
        <ContentSection>
          <ContentNote>My past</ContentNote>
          <h2 className="subheader">Learned as I went</h2>
          <p className="bodycopy">
            I have an education in visual journalism, and with that came my
            strong focus on the stories we tell. How we tell them, and to whom.
          </p>
          <p className="bodycopy">
            I was professionally born and raised by a digital design and
            innovation agency in Copenhagen. In and out of agile mindsets, from
            corporate to creatives. Some minimal viable products, but always -
            maximum f***ing love.
          </p>
          <p className="bodycopy">
            As an interface developer I spent my time to discern the feasible
            from the fictionary. Where and how can we use technology to gain the
            effects we want and need.
          </p>
        </ContentSection>
        <ContentSection>
          <ContentNote>My present</ContentNote>
          <h2 className="subheader">After hours</h2>
          <p className="bodycopy">
            At the moment most of my spare time goes into my little family, wich
            recently got doubled in size. So, as a father of twin boys, I juggle
            diapers at the same time I slice fruit in non-suffocating sizes, all
            while keeping O from stealing toys from S. Im kind of proud of that.
          </p>
          <p className="bodycopy">
            If I can find the time at all, I enjoy boardgames with friends and
            family. The joy of the tactile miniatures and pieces of cardboard,
            all while spending face to face time without distractions, is
            invaluable to me.
          </p>
          <p className="bodycopy">
            Oh, and I walk my dog Elvis, even though the little fluff-ball
            mostly likes to curl up on the couch.
          </p>
        </ContentSection>
        <ContentSection>
          <ContentNote>My looks</ContentNote>
          <h2 className="subheader">A face to the words</h2>
          <div
            css={mq({
              gridColumn: ["2 / 6", "3 / 7", "4 / 7"],
            })}
          >
            <Image />
          </div>
        </ContentSection>
        <ContentSection>
          <ContentNote>My beliefs</ContentNote>
          <h2 className="subheader">In no particular order</h2>
          <ContentList>
            <li>
              <i>"Its not for you"</i> -{" "}
              <Link href="https://www.goodreads.com/book/show/40549476-this-is-marketing">
                Seth Godin
              </Link>
            </li>
            <li>
              ! or ? -{" "}
              <Link href="https://www.youtube.com/watch?v=0nI65jgHG9o">
                Chip Kidd
              </Link>
            </li>
            <li>
              Differentiate or Die -{" "}
              <Link href="https://www.goodreads.com/book/show/105146.Differentiate_or_Die">
                Jack Trout and Steve Rivkin
              </Link>
            </li>
            <li>
              FAIL = First Attempt In Learning -{" "}
              <Link href="https://www.youtube.com/watch?v=qroMSh8UH8g">
                Chris Do
              </Link>
            </li>
            <li>
              Curiosity trumps knowing -{" "}
              <Link href="https://www.goodreads.com/book/show/12579973-the-trusted-advisor-fieldbook">
                Charles H Green
              </Link>
            </li>
            <li>
              <i>"No, no, you're not thinking; you're just being logical"</i> -
              Niels Bohr
            </li>
          </ContentList>
        </ContentSection>
      </PageMain>
    </>
  )
}

export default IndexPage
