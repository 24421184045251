import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { mq, colors } from "../styles/variables"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "torkil_in_tech-portrait.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        css={mq({
          position: "relative",
          borderRadius: 1000,
          border: "1px solid " + colors.primary,
        })}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
    )}
  />
)
export default Image
